import {createRouter, createWebHistory} from 'vue-router'
/* Layouts */
import Home from '@/components/Home'
import Proyectos from '@/components/Proyectos'
import ProyectoDetalle from '@/components/ProyectoDetalle'
import Contacto from '@/components/Contacto'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/proyectos',
    name: 'proyectos',
    component: Proyectos,
  },
  {
    path: '/proyecto/:id',
    name: 'proyecto',
    component: ProyectoDetalle,
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contacto,
  }
]

const router = new createRouter({
  history: createWebHistory(),
  routes
})

export default router
