<template>
  <div>
    <header>
      <Header></Header>
    </header>
    <div class="content">
      <b-row>
        <b-col md="12 mb-2">
            <section class="info mb-4 mt-4">
                <h4 class="title">{{ proyecto.nombre }}</h4>
                <a :href="proyecto.url" target="_blank" class="btn btn_sitio">
                  Ver Web
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                  </svg>
                </a>
            </section>
            <img :src="proyecto.img" class="img-proyecto mb-4" alt="Topoingenieria"/>
            <section class="info">
                <p>
                    {{ proyecto.descripcion }}
                </p>
            </section>
            <section class="info">
                <h4 class="title">Hecho en:</h4>
                <img v-for="(icon, i) in proyecto.icons" class="m-2" :key="i" :src="icon.img" alt="">
            </section>
        </b-col>
      </b-row>
    </div>
    <div class="footer">
        <img :src="vector_l" class="vector-l1" alt="vector l">
    </div>
  </div>
</template>

<script>
import Header from '../components/sections/Header.vue'
export default {
  name: 'HomeUrPage',
  components: { Header },
  props: {},
  data() {
    return {
      logo: require('../assets/img/logo.png'),
      urpage: require('../assets/img/urpage.png'),
      vector_l: require('../assets/img/vector-l1.svg'),
      link_icon: require('../assets/img/icon/url.svg'),
      proyecto: {},
      proyectos: [
        {
            id: 1,
            nombre: 'TopoIngenieria',
            descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est luctus in mauris felis, duis ac in. Iaculis ornare ut urna, venenatis libero. Quis massa ut sit diam. Mattis mauris cras mi tellus, lobortis orci in dolor.',
            url: 'https://topoingenieria.com/',
            img: require('../assets/img/proyectos/detalle/topoingenieria.svg'),
            icons: [
                {
                    img: require('../assets/img/icon/wp.svg')
                }
            ]
        },
        {
            id: 2,
            nombre: 'Urban Plus',
            descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est luctus in mauris felis, duis ac in. Iaculis ornare ut urna, venenatis libero. Quis massa ut sit diam. Mattis mauris cras mi tellus, lobortis orci in dolor.',
            url: 'https://urbanplus.app/',
            img: require('../assets/img/proyectos/detalle/urbanplus.svg'),
            icons: [
                { img: require('../assets/img/icon/html.svg') },
                { img: require('../assets/img/icon/css.svg') }
            ]
        },
        {
            id: 3,
            nombre: 'Alma Cielo y Mar',
            descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est luctus in mauris felis, duis ac in. Iaculis ornare ut urna, venenatis libero. Quis massa ut sit diam. Mattis mauris cras mi tellus, lobortis orci in dolor.',
            url: 'https://almacieloymar.ur-page.com/',
            img: require('../assets/img/proyectos/detalle/almacieloymar.svg'),
            icons: [
                { img: require('../assets/img/icon/html.svg') },
                { img: require('../assets/img/icon/css.svg') }
            ]
        },
        {
            id: 4,
            nombre: 'IMM',
            descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est luctus in mauris felis, duis ac in. Iaculis ornare ut urna, venenatis libero. Quis massa ut sit diam. Mattis mauris cras mi tellus, lobortis orci in dolor.',
            url: 'https://imm-ca.com/',
            img: require('../assets/img/proyectos/detalle/imm.svg'),
            icons: [
                {
                    img: require('../assets/img/icon/wp.svg')
                }
            ]
        }
      ]
    }
  },
  mounted () {
      console.log(this.proyectos[this.$route.params.id])
      this.proyecto = this.proyectos[this.$route.params.id - 1]
  }
}
</script>

<style scoped>
.content{
  width: 80%;
  margin: auto;
  position: relative;
  padding-top: 100px;
  z-index: 99;
  text-align: center;
}
.img-proyecto{
  width: 90%;
}
.content section{
  width: 90%;
  margin: auto;
  text-align: left;
}
.info p{
  text-align: justify;
}
.icon_url{
  width: 30px;
}
.title, p{
  color: white;
}
.btn_sitio{
  width: 180px;
  height: 45px;
  color: white;
  letter-spacing: 3px;
  font-weight: 400;
  background: #6E7DD8;
  padding: 0.6em;
}
.btn_sitio:hover{
  color: black;
  background: linear-gradient(196.36deg, #9FFFE6 11.78%, rgba(112, 110, 250, 0.9) 104.88%);
}
.img-sitio{
  width: 25px;
}
</style>
