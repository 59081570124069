<template>
    <router-link :to="{ name: 'home' }"><img :src="logo" class="logo" alt="LogoUrPage"></router-link>
</template>

<script>
export default {
  name: 'HomeUrPage',
  props: {},
  data() {
      return {
            logo: require('../../assets/img/logo.png')
      }
  }
}
</script>
