<template>
  <div>
    <header>
      <Header></Header>
    </header>
    <div class="content">
      <b-row>
        <b-col md="6 mb-2" v-for="(proyecto,index) in proyectos" :key="index">
          <img :src="proyecto.img" class="img-proyecto" alt="Topoingenieria"/>
          <section class="info mt-2">
            <div class="d-title">
              <h4 class="title">{{ proyecto.nombre }}</h4>
            </div>
            <div class="d-icon">
               <router-link :to="{ name: 'proyecto', params: { id: proyecto.id } }"><img :src="link_icon" class="icon_url" alt="url site"/></router-link>
            </div>
          </section>
        </b-col>
      </b-row>
    </div>
    <div class="footer">
        <img :src="vector_l" class="vector-l1" alt="vector l">
    </div>
  </div>
</template>

<script>
import Header from '../components/sections/Header.vue'
export default {
  name: 'HomeUrPage',
  components: { Header },
  props: {},
  data() {
    return {
      logo: require('../assets/img/logo.png'),
      urpage: require('../assets/img/urpage.png'),
      vector_l: require('../assets/img/vector-l1.svg'),
      link_icon: require('../assets/img/icon/url.svg'),
      proyectos: [
        { id: 1, nombre: 'TopoIngenieria', descripcion: '', url: 'https://topoingenieria.com/', img: require('../assets/img/proyectos/topoingenieria.svg') },
        { id: 2, nombre: 'Urban Plus', descripcion: '', url: 'https://urbanplus.app/', img: require('../assets/img/proyectos/urbanplus.svg') },
        { id: 3, nombre: 'Alma Cielo y Mar', descripcion: '', url: 'https://almacieloymar.ur-page.com/', img: require('../assets/img/proyectos/almacieloymar.svg') },
        { id: 4, nombre: 'IMM', descripcion: '', url: 'https://imm-ca.com/', img: require('../assets/img/proyectos/imm.svg') }
      ]
    }
  }
}
</script>

<style scoped>
.content{
  width: 80%;
  margin: auto;
  position: relative;
  padding-top: 100px;
  z-index: 99;
  text-align: center;
}
.img-proyecto{
  width: 90%;
}
.content section{
  text-align: left;
  margin-left: 1.8em;
  margin-right: 1.8em;
}
.info .d-title{
  width: 80%;
  float: left;
}
.info .d-icon{
  widows: 20%;
  float: right;
  text-align: right;
}
.info div{
  display: inline-block;
  vertical-align: middle;
}
.icon_url{
  width: 30px;
}
.title{
  color: white;
}
</style>
