<template>
  <div>
    <div id="particle-container">
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
    </div>
    <header>
      <Header></Header>
    </header>
    <div class="body">
        <img :src="urpage" alt="UrPage">
        <ul>
          <li><router-link :to="{ name: 'proyectos' }">Proyectos</router-link></li>
          <li><router-link :to="{ name: 'proyectos' }">Contacto</router-link></li>
        </ul>
    </div>
    <div class="footer">
        <img :src="vector_l" class="vector-l" alt="vector l">
        <img :src="vector_r" class="vector-r" alt="vector r">
    </div>
  </div>
</template>

<script>
import Header from '../components/sections/Header.vue'
export default {
  name: 'HomeUrPage',
  components: { Header },
  props: {},
  data() {
    return {
      logo: require('../assets/img/logo.png'),
      urpage: require('../assets/img/urpage.png'),
      vector_l: require('../assets/img/vector-l.svg'),
      vector_r: require('../assets/img/vector-r.svg')
    }
  }
}
</script>

<style scoped>
ul li {
  display: inline-block;
  margin-right: 30px;
  font-size: 20px;
}

ul li a{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
</style>
