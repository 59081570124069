<template>
  <div>
    <div id="particle-container">
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
    </div>
    <header>
      <Header></Header>
    </header>
    <div class="body">
      
    </div>
    <div class="footer">
        <img :src="vector_l" class="vector-l1" alt="vector l">
    </div>
  </div>
</template>

<script>
import Header from '../components/sections/Header.vue'
export default {
  name: 'HomeUrPage',
  components: { Header },
  props: {},
  data() {
    return {
      logo: require('../assets/img/logo.png'),
      urpage: require('../assets/img/urpage.png'),
      vector_l: require('../assets/img/vector-l1.svg')
    }
  }
}
</script>
